export default {
  pending: {
    class: 'bg-warning text-warning',
    label: 'Pending',
    name: 'pending',
  },
  approved: {
    class: 'bg-success text-success',
    label: 'Approved',
    name: 'approved',
  },
  applied: {
    class: 'bg-success text-success',
    label: 'Effective',
    name: 'applied',
  },
  declined: {
    class: 'bg-danger text-danger',
    label: 'Rejected',
    name: 'declined',
  },
  overridden: {
    class: 'bg-warning text-warning',
    label: 'Overridden',
    name: 'overridden',
  },
};
