export default {
  created() {
    const newValues = this.parseRoute();
    Object.assign(this.$data, newValues);
    this.loadPage(this.page);
  },
  data() {
    return Object.assign(this.getDefaults(), {
      filter: '',
      items: [],
      meta: {
        total: 0,
        totalPages: 1,
      },
    });
  },
  methods: {
    getDefaults() {
      return {
        limit: 15,
        order: {
          direction: '',
          field: '',
        },
        page: 1,
        query: '',
        listLoading: false,
      };
    },
    goToPage(e) {
      this.$router.replace({
        query: Object.assign({}, this.$route.query, {
          page: e.page > 1 ? e.page : undefined,
        }),
      });
    },
    async loadPage(page) {
      try {
        this.listLoading = true;
        const result = this.transformData(await this.fetchData(page));
        Object.assign(this.$data, {
          items: result.items,
          meta: {
            total: result.links.total,
            totalPages: result.links.last_page,
          },
        });
      }
      catch (error) {
        console.error(error);
      }
      finally {
        this.listLoading = false;
      }
    },
    parseRoute() {
      const params = this.$route.query;
      const newValues = this.getDefaults();

      if (params.limit !== undefined && params.limit !== null) { newValues.limit = Number(params.limit); }

      if (params.order !== undefined && params.order !== null) {
        const values = params.order.split('|');
        newValues.order = {direction: values[1] || 'asc', field: values[0]};
      }

      if (params.page !== undefined && params.page !== null) { newValues.page = Number(params.page); }

      if (params.query !== undefined && params.query !== null) { newValues.query = params.query; }

      return newValues;
    },
    refresh() {
      this.loadPage(this.page);
    },
    transformData(result) {
      return result;
    },
  },
  watch: {
    $route() {
      const newValues = this.parseRoute();

      if (newValues.order.field !== this.order.field || newValues.order.direction !== this.order.direction || newValues.query !== this.query) {
        Object.assign(this.$data, newValues);
        this.loadPage(1);
      }
      else if (newValues.limit !== this.limit || newValues.page !== this.page) {
        Object.assign(this.$data, newValues);
        this.loadPage(this.page);
      }
    },
  },
};
