<template>
  <div class="d-flex justify-content-between align-items-center sort-wrap" @click="setDirection">
    <div class="text-truncate"><slot></slot></div>
    <div class="sorters">
      <template v-if="value === 'asc'"><i class="fe fe-chevron-up"></i></template>
      <template v-else-if="value === 'desc'"><i class="fe fe-chevron-down"></i></template>
      <template v-else>
        <div class="fe fe-chevron-up"></div>
        <div class="fe fe-chevron-down"></div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HTableSorter',
  props: {
    value: {
      type: [String],
      default: null,
    },
  },
  methods: {
    setDirection() {
      let direction = null;

      if (!this.value) {
        direction = 'desc';
      }
      else if (this.value === 'desc') {
        direction = 'asc';
      }

      this.$emit('sorting', direction);
    },
  },
};
</script>

<style scoped>
  .sort-wrap {
    cursor: pointer;
    gap: 0.5rem;
  }
</style>
