<template>

  <nav>
    <ul class="pagination justify-content-end m-3">
      <li class="page-item" :class="{disabled: (currentPage <= 1)}">
        <a
          class="page-link"
          tabindex="0"
          aria-label="First"
          @click.prevent="raiseGoTo(1)"><span aria-hidden="true">««</span></a>
      </li>
      <li class="page-item" :class="{disabled: (currentPage <= 1)}">
        <a
          class="page-link"
          tabindex="0"
          aria-label="Previous"
          @click.prevent="raisePrevious"><span aria-hidden="true">«</span></a>
      </li>
      <li
        v-for="(n, index) in visiblePages"
        :key="index"
        class="page-item"
        :class="{active: (currentPage === n)}"
        @click.prevent="raiseGoTo(n)">
        <a class="page-link" tabindex="0">{{n}}</a>
      </li>
      <li class="page-item" :class="{disabled: (currentPage >= totalPages)}">
        <a
          class="page-link"
          tabindex="0"
          aria-label="Next"
          @click.prevent="raiseNext"><span aria-hidden="true">»</span></a>
      </li>
      <li
        data-test="page-item-last"
        class="page-item"
        :class="{disabled: (currentPage >= totalPages)}">
        <a
          class="page-link"
          tabindex="0"
          aria-label="Last"
          @click.prevent="raiseGoTo(totalPages)"><span aria-hidden="true">»»</span></a>
      </li>
    </ul>
  </nav>

</template>

<script>

import range from 'lodash/range';

export default {
  props: [

    'currentPage',
    'totalPages',
  ],
  data() {
    return {

      pagesToShow: 3,
    };
  },

  computed: {

    firstPage() {
      const group = Math.ceil(this.currentPage / this.pagesToShow);
      return (group - 1) * this.pagesToShow + 1;
    },
    visiblePages() {
      const first = this.firstPage;
      const last = Math.min(first + this.pagesToShow, Math.max(this.totalPages, 1) + 1);

      return range(first, last);
    },
  },
  methods: {

    raiseGoTo: function(page) { this.$emit('goToPage', {page: page}); },
    raiseNext: function() { this.$emit('goToPage', {page: this.currentPage + 1}); },
    raisePrevious: function() { this.$emit('goToPage', {page: this.currentPage - 1}); },
  },
};

</script>
