<template>
  <div class="container">
    <div class="page-header">
      <h1 class="mb-0">Article price changes</h1>
    </div>

    <div class="d-flex flex-row-reverse align-items-center justify-content-between mb-3">
      <div class="row d-flex align-items-center gap-8">
        <label class="form-label"> Status: </label>
        <div>
          <label
            v-for="(status, key) in statusArray"
            :key="key"
            class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              :checked="status.checked"
              @change="onStatusChange(status)">
            <span class="form-check-label">{{status.label}}</span>
          </label>
        </div>
      </div>
      <div>
        <span class="text-truncate">Showing {{numeral(items.length).format('0,0')}} of
          {{numeral(meta.total).format('0,0')}} total requests
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="card">
          <div class="table-responsive">
            <dimmer :active="listLoading">
              <table class="table table-outline table-vcenter card-table">
                <thead>
                  <tr>
                    <th> Article </th>
                    <th> Supplier </th>
                    <th>Current price</th>
                    <th>To price</th>
                    <th v-b-tooltip="'Consumption is retrieved from the past six months orders'">
                      Consumption
                    </th>
                    <th>Value difference</th>
                    <th>
                      <h-table-sorter
                        :value="sortableFields.effective_date"
                        @sorting="direction => getSort(direction, 'effective_date')">
                        Effective date
                      </h-table-sorter>
                    </th>
                    <th>Comment</th>
                    <th>Requested by</th>
                    <th class="text-center">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td>
                      <router-link v-if="can(uiPermissions.ARTICLES_VIEW)" :to="`/articles/${item.article_id}`">
                        {{item.article_name}}
                      </router-link>
                      <span v-else>{{item.article_name}}</span>
                    </td>
                    <td>
                      {{item.supplier_name}}
                    </td>
                    <td>
                      {{numeral(item.from_cost_price).format('0,0[.]00')}}
                    </td>
                    <td>
                      {{numeral(item.to_cost_price).format('0,0[.]00')}}
                    </td>
                    <td>
                      {{item.consumption_quantity}}
                    </td>
                    <td>
                      {{numeral(item.consumption_value_difference).format('0,0[.]00')}}
                    </td>
                    <td>
                      {{item.effective_date}}
                    </td>
                    <td>
                      {{item.comment || 'NA'}}
                    </td>
                    <td>
                      {{item.created_by.firstName}}
                    </td>
                    <td>
                      <div
                        v-if="item.status === 'pending' && can(uiPermissions.ARTICLES_COST_PRICE_CHANGES_UPDATE)"
                        class="d-flex col justify-content-center align-items-center gap-4">
                        <button
                          v-b-tooltip="'Approve'"
                          type="submit"
                          class="btn btn-success ml-auto"
                          @click.prevent="onAction('approve', item)">
                          <i class="fe fe-check-circle"></i>
                        </button>
                        <button
                          v-b-tooltip="'Reject'"
                          type="submit"
                          class="btn btn-danger ml-auto"
                          @click.prevent="onAction('reject', item)">
                          <i class="fe fe-x-circle"></i>
                        </button>
                      </div>
                      <div v-else class="d-flex align-items-center">
                        <i
                          class="fe fe-circle rounded-circle mr-1"
                          :class="ArticlePriceChangeStatus[item.status].class">
                        </i>
                        <span>
                          {{ArticlePriceChangeStatus[item.status].label}}
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="items.length === 0">
                    <td colspan="100%" class="text-center">There are no results matching your criteria.</td>
                  </tr>
                </tbody>
              </table>
            </dimmer>
          </div>
          <pagination
            :currentPage="page"
            :totalPages="meta.totalPages"
            @goToPage="goToPage"></pagination>
        </div>
      </div>
    </div>

    <b-modal
      v-model="priceChange.showModel"
      size="md"
      title="Confirm price change"
      hide-footer
      @hide="closeConfirmModal">
      <template slot="modal-header-close">
        <wbr/>
      </template>
      <p v-if="priceChange.item">
        Are you sure you want to
        <b :class="[approvalStatus[priceChange.type], 'text-uppercase']">{{priceChange.type}}</b> the price change from
        <b>AED {{numeral(priceChange.item.from_cost_price).format('0,0[.]00')}}</b> to
        <b>AED {{numeral(priceChange.item.to_cost_price).format('0,0[.]00')}}</b> for the article
        <b>{{priceChange.item.article_name}}</b> from supplier <b>{{priceChange.item.supplier_name}}</b>?
      </p>
      <div class="d-flex justify-content-end pt-2">
        <button class="btn btn-primary mr-2" @click.prevent="onConfirmChange">Confirm</button>
        <button class="btn" @click.prevent="closeConfirmModal">Cancel</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ArticlePriceChangeStatus from '@/assets/enums/ArticlePriceChangeStatus';
import Pagination from '@/components/Pagination.vue';
import HTableSorter from '@/components/ui/HTableSorter';
import list from '@/mixins/list';
import {articles} from '@/services';
import submitting from '@hellochef/shared-js/mixins/submitting';

export default {
  components: {
    Pagination,
    HTableSorter,
  },
  mixins: [
    list,
    submitting,
  ],
  data() {
    return {
      ArticlePriceChangeStatus,
      selectedFilters: {
        status: this.$route.query.status ?? ['pending'],
      },
      sortableFields: {
        effective_date: null,
        consumption_value_difference: null,
      },
      approvalStatus: {
        'approve': 'text-success',
        'reject': 'text-danger',
      },
      priceChange: {
        showModel: false,
        item: null,
        type: null,
      },
    };
  },
  computed: {
    filters() {
      // to remove a empty value property
      let qp = Object.fromEntries(Object.entries(this.selectedFilters).filter(([_, v]) => v !== ''));
      // retain if the query exists
      if (this.query) {
        qp = {
          ...qp,
          query: this.query,
        };
      }
      return qp;
    },
    sorts() {
      let sort = {};
      Object.keys(this.sortableFields).forEach(key => {
        const value = this.sortableFields[key];
        if (value) {
          sort = {
            column: key,
            direction: value,
          };
        }
      });
      return sort;
    },
    statusArray() {
      // get all the change status
      const statuses = Object.assign({}, ArticlePriceChangeStatus);
      // hide overridden from the list
      delete statuses.overridden;
      // convert to array and assign checked property
      const statusArray = Object.values(statuses).map(it => {
        return {
          ...it,
          checked: this.selectedFilters.status.includes(it.name),
        };
      });
      this.$router.replace({query: this.filters});
      return statusArray;
    },
  },
  methods: {
    fetchData(page) {
      // get and return data
      return articles.getCostPriceChanges({
        limit: this.limit,
        page,
        ...this.sorts,
        ...this.filters,
        with: 'createdBy,actionedBy',
      });
    },
    onStatusChange(status) {
      status.checked = !status.checked;
      this.selectedFilters.status = this.statusArray.filter(it => it.checked)?.map(it => it.name.toLowerCase());
      this.applyFilters();
    },
    applyFilters() {
      this.goToPage({page: 1});
      this.$router.replace({query: this.filters});
      this.refresh();
    },
    getSort(direction, field) {
      Object.keys(this.sortableFields).forEach(field => {
        this.sortableFields[field] = null;
      });
      this.sortableFields[field] = direction;
      this.refresh();
    },
    transformData(result) {
      result.items = result.cost_price_change;
      return result;
    },
    onAction(type, item) {
      this.priceChange.showModel = true;
      this.priceChange.type = type;
      this.priceChange.item = item;
    },
    async onConfirmChange() {
      try {
        const {id} = this.priceChange.item;
        const status = this.priceChange.type === 'approve' ? 'approved' : 'declined';
        await articles.updateCostPriceChange(id, {
          status,
        });
        this.closeConfirmModal();
        this.refresh();
      }
      catch (error) {
        console.error(error);
        this.$toasted.error('Something went wrong');
      }
    },
    closeConfirmModal() {
      this.priceChange = {
        showModel: false,
        item: null,
        type: null,
      };
    },
  },
};

</script>

<style scoped>
.table thead th,
.text-wrap table thead th {
  vertical-align: middle;
}
</style>
